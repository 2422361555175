















































































import { VueAgile } from "vue-agile";
import { defineComponent, computed, ref, PropType } from "@vue/composition-api";
import { NewsSliderConfigType } from "./NewsSliderConfigType.types";

export default defineComponent({
  name: "NewsSlider",
  components: {
    agile: VueAgile,
  },
  props: {
    config: {
      type: Object as PropType<NewsSliderConfigType>,
      required: true,
    },
  },
  setup(props) {
    const config = computed(() => props.config);
    const records = computed(() => config.value?.records ?? []);
    const moreThanOneSlide = computed(() => {
      return records.value?.length > 1 ? true : false;
    });
    const myOptions = {
      responsive: [
        {
          breakpoint: 200,
          settings: {
            navButtons: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            navButtons: moreThanOneSlide.value,
          },
        },
      ],
    };

    const getButtonLink = (path: string) => {
      return path.startsWith("/")
        ? path
        : path.indexOf("://") == -1
        ? "https://" + path
        : path;
    };

    return {
      config,
      myOptions,
      moreThanOneSlide,
      getButtonLink
    };
  },
});
