import Vue from "@/helper/initVue";

import { NewsSlider } from "@/features/news-slider";

const elements = document.querySelectorAll(".news-slider");

Array.from(elements).forEach((element) => {
  try {
    const config = element.getAttribute("data-config");
    let parsedConfig = {};
    if (config) {
      parsedConfig = JSON.parse(config);
    }
    if (element) {
      new Vue({
        el: element,
        render: (h) =>
          h(NewsSlider, {
            props: {
              config: parsedConfig,
            },
          }),
      });
    }
  } catch (err) {
    console.log("Error");
  }
});
